<template>
  <div id="programme-page">
    <div v-if="availableProgrammesReady" class="programme-banner">
      <k-banner v-if="programmeDetails" :cover-image="coverImage">
        <div class="heading-container content-with-spacing">
          <div class="title-container">
            <h1 v-if="programmeDetails">
              <i class="fas fa-chart-bar"></i> {{ programmeDetails.name }}
            </h1>
          </div>
          <div v-if="availableProgrammes.length > 1" class="header-dropdown">
            <span class="dropdown-label">Programmes:</span>
            <k-dropdown :options="availableProgrammes" v-model="programmeIdFromDropdown"></k-dropdown>
          </div>
          <div v-if="programmeDetails" class="intro-text">
            <div v-if="programmeDetails.description" v-html="$kpurify.sanitise(programmeDetails.description)"></div>
            <p v-else><b class="welcome-text">Welcome to the {{programmeDetails.name}} programme!</b><br />
              Below is a list of modules for this programme. To get started on your first module, click on the "View Module" button.
            </p>
          </div>
        </div>
      </k-banner>
    </div>
    <div class="content-with-spacing" v-if="availableProgrammesReady && programmeIdFromRoute">
      <programme-content
        :programme-id="programmeIdFromRoute"
        @modulesReady="programmeModulesReady = true">
      </programme-content>
    </div>
    <k-chat-box v-if="$featureFlags.isEnabled('kate-bot', 'programme-page') && programmeDetails?.chatbot_enabled"
      :programmeId="programmeIdFromRoute">
    </k-chat-box>
  </div>
</template>

<style>
/* Dropdown */
.header-dropdown .k-search-dropdown-menu {
  min-width: 250px;
}

/* If navcrumb is empty */
.content-with-spacing .k-breadcrumb:empty {
  display: none;
}

</style>

<style scoped>
.heading-container {
  display: flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px 0;
  margin-bottom: 30px;
}

.heading-container .dropdown-label {
  margin-right: 0.5em;
}

.title-container {
  flex: 1 1 60%;
  align-self: center;
}

.title-container h1 {
  text-transform: capitalize;
  padding: 15px 0 !important;
}

.header-dropdown {
  display: flex;
  align-items: center;
}

.intro-text {
  width: 100%;
  margin-bottom: 15px;
}

.intro-text a i {
  padding-left: 10px;
}

@media (max-width: 1204px) {
  .header-dropdown {
    margin-bottom: 20px;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .title-container h1 {
    margin: 0 !important;
  }

  .programme-banner > .heading-container {
    margin-top: 0 !important;
  }
}
</style>

<script>
import useGlobalStore from '../stores/global';
import KDropdown from '../components/k-dropdown.vue';
import TimeMixin from '../mixins/time-mixins';
import StorageMixin from '../mixins/storage-mixins';
import ErrorMixin from '../mixins/error-mixins';
import PageReadyMixin from '../mixins/page-ready-mixin';
import ProgrammeContent from './programme-content.vue';
import KBanner from './k-banner.vue';
import KChatBox from '../components/chatbot/k-chat-box.vue';

export default {
  components: {
    KDropdown,
    ProgrammeContent,
    KBanner,
    KChatBox,
  },

  mixins: [TimeMixin, StorageMixin, ErrorMixin, PageReadyMixin],

  data() {
    return {
      store: useGlobalStore(),
      availableProgrammes: [],
      availableProgrammesReady: false,
      initialRouteResolutionComplete: false,
      programmeModulesReady: false,
      programmeIdFromDropdown: undefined,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getAvailableProgrammes();
  },

  watch: {
    availableProgrammesReady() {
      if (this.availableProgrammesReady) {
        this.$logger.info('Available programmes ready, resolving programme ID');
        this.resolveProgrammeId();
        this.initialRouteResolutionComplete = true;
      }
    },
    programmeIdFromDropdown: {
      handler() {
        if (!this.programmeIdFromDropdown) {
          return;
        }
        this.saveCurrentProgramme(this.programmeIdFromDropdown);
        if (this.programmeIdFromDropdown !== this.programmeIdFromRoute) {
          this.$router.replace({
            name: 'programme_curriculum',
            params: {
              programmeId: this.programmeIdFromDropdown,
            },
          });
        }
      },
      deep: true, // required for this to trigger during resolveProgrammeId
    },
    programmeDetails() {
      if (this.programmeDetails) {
        this.registerCrumbs();
      }
    },
    programmeIdFromRoute() {
      if (this.initialRouteResolutionComplete && this.$route.name === 'programme_page' && !this.programmeIdFromRoute) {
        // After initial load, if there is ever no programme ID in the route, re-resolve the programmeId
        this.$logger.info('No programme ID in route, re-resolving');
        this.resolveProgrammeId();
      }
    },
  },

  computed: {
    ready() {
      return this.availableProgrammesReady && this.programmeModulesReady;
    },
    sortedProgrammes() {
      return this.availableProgrammes.slice().sort(this.sortProgrammes);
    },
    programmeIdFromRoute() {
      const isProgrammeRoute = this.$route.matched.some(record => record.path === '/programmes/:programmeId?/:track?');

      if (!isProgrammeRoute
          || this.$route.params.programmeId === undefined
          || Number.isNaN(Number(this.$route.params.programmeId))) {
        return undefined;
      }
      return Number(this.$route.params.programmeId);
    },
    programmeDetails() {
      return this.availableProgrammes.filter(x => x.id === this.programmeIdFromRoute)[0];
    },
    sanitisedDescription() {
      return this.$kpurify.sanitise(this.programmeDetails.description);
    },
    apiEndpoint() {
      return '/api/curriculum/programmes';
    },
    coverImage() {
      return this.programmeDetails?.cover_image;
    },
  },

  methods: {
    resolveProgrammeId() {
      this.programmeIdFromDropdown = undefined;
      this.$logger.info('Resolving programme ID from route or store', undefined, true);
      const newProgrammeId = this.programmeIdFromRoute || this.loadCurrentProgramme();
      if (newProgrammeId && !this.availableProgrammes.find(x => x.id === newProgrammeId)) {
        this.$logger.warn('User is not a member of the programme they are trying to access, routing to 404');
        this.clearCurrentProgramme();
        this.$router.push({ name: '404' });
        return;
      }
      if (!newProgrammeId) {
        if (this.availableProgrammes.length > 0) {
          this.$logger.info('No programme ID found - defaulting to first available programme');
          this.programmeIdFromDropdown = this.availableProgrammes[0].id;
        } else {
          this.$logger.info('User is not a member of any programme');
          this.clearCurrentProgramme();
          this.$router.push('/user_not_in_programme');
        }
      } else {
        this.$logger.info('Programme ID resolved - updating programme ID in dropdown', { newProgrammeId });
        this.programmeIdFromDropdown = newProgrammeId;
      }
    },
    registerCrumbs() {
      this.$crumbs.register([
        {
          text: this.programmeDetails.name,
          active: true,
        },
      ]);
    },
    getAvailableProgrammes() {
      this.availableProgrammesReady = false;
      this.$logger.info('Getting available programmes');
      this.$http.get(this.apiEndpoint)
        .then(response => {
          this.availableProgrammes = response.data.programmes;
          this.$logger.info('Got available programmes');
        })
        .catch(err => {
          this.showError(err, true);
        }).then(() => {
          this.availableProgrammesReady = true;
        });
    },
    saveCurrentProgramme(programmeId) {
      this.$logger.info('Saving current programme to store', programmeId);
      this.saveSetting('programme_page', 'currentProgramme', programmeId);
    },
    loadCurrentProgramme() {
      this.$logger.info('Loading current programme from store', undefined, true);
      return this.loadSetting('programme_page', 'currentProgramme');
    },
    clearCurrentProgramme() {
      this.$logger.info('Clearing current programme from store', undefined, true);
      this.saveSetting('programme_page', 'currentProgramme', undefined);
    },
  },
};
</script>
