<template>
  <div v-if="ready">
    <empty-placeholder v-if="emptyProgramme"
      title="No modules available in this programme"
      :info="emptyProgrammeText"
      :fa-icon="emptyIcon"
    ></empty-placeholder>
    <template v-else>
      <div class="filters-container">
        <div class="nav-tabs-wrapper">
          <k-nav-tabs :currentTab="currentTab" :tabs="showTabs"></k-nav-tabs>
        </div>
        <tab-controls :currentTab="currentTab"
          :sortDescending="sortDescending"
          :setModulesLayout="setModulesLayout"
          @toggleSort="toggleSort"
          @toggleLayout="toggleLayout"
          @filter="filterCompetencies"
        />
      </div>
      <router-view v-if="orderedModules.length > 0 || competencies.length > 0"
        v-bind="pageProps"
        :key="programmeId"
        @save-competency="saveCompetency"
      ></router-view>
      <empty-placeholder v-else
        :title="emptyTrackTitle"
        :info="emptyTrackText"
        :fa-icon="emptyIcon"
      ></empty-placeholder>
    </template>
  </div>
</template>

<style>
.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
  grid-column-gap: 15px;
}

.grid-view .panel-horizontal .module-content {
  gap: 15px;
  justify-content: flex-start;
}

.grid-view .module-body {
  flex-direction: column;
  text-align: left;
}

.grid-view .module-progress-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
  column-gap: 15px;
  justify-content: space-between;
}

.grid-view .completion-date,
.grid-view .module-body,
.grid-view .module-progress-container {
  margin: 0;
  padding: 0;
}

.grid-view .k-progress {
  flex: 1 1 25%;
  min-width: 150px;
}

.grid-view .completion-date {
  flex: 1 1 70%;
  max-width: 340px;
}

@media (max-width: 842px) {
  .grid-view .module-button-container {
    text-align: center;
  }

  .grid-view .module-description {
    max-height: unset;
  }
}

@media (min-width: 544px) and (max-width: 670px) {
  .grid-view .module-progress-container {
    display: block;
  }

  .grid-view .k-progress {
    width: 100%;
    max-width: unset;
  }
}

@media (max-width: 768px) {
  .grid-view .k-progress {
    max-width: unset;
    width: 100%;
  }
}
</style>

<style scoped>
.filters-container {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.filters-container ul {
  float: left;
}

.nav-tabs-wrapper {
  margin: inherit;
}

.filters-container .btn-default:focus,
.filters-container .btn-default:hover {
  background-color: var(--kate-background-alt-alpha);
  border-color: var(--kate-background-alt-alpha);
}

</style>

<script>
import useGlobalStore from '../stores/global';
import TimeMixin from '../mixins/time-mixins';
import ErrorMixin from '../mixins/error-mixins';
import StorageMixin from '../mixins/storage-mixins';
import KNavTabs from '../components/k-nav-tabs.vue';
import EmptyPlaceholder from '../components/empty-placeholder.vue';
import { genericSort } from '../modules/sort-by-object-property';
import stringMatch from '../modules/string-match';
import TabControls from './components/tab-controls.vue';
import PageReadyMixin from '../mixins/page-ready-mixin';

export default {
  props: {
    programmeId: {
      type: Number,
      required: true,
    },
  },

  components: {
    EmptyPlaceholder,
    KNavTabs,
    TabControls,
  },

  mixins: [TimeMixin, ErrorMixin, StorageMixin, PageReadyMixin],

  data() {
    return {
      programmeModulesReady: false,
      programmeModules: [],
      filterText: '',
      selectedCategory: undefined,
      store: useGlobalStore(),
      competenciesReady: false,
      competencies: [],
      categoryFilterFunc: () => true,
    };
  },

  beforeMount() {
    this.getCompetencies();
    this.$Loading.start();
    this.getProgrammeModules();

    // Load appropriate sorting state based on current tab
    if (this.currentTab === 'programme_competencies') {
      const sortingState = this.loadSetting('programme-content', 'competenciesSortingState');
      if (sortingState !== undefined) {
        this.store.setModulesSorting(sortingState);
      }
    } else {
      const sortingState = this.loadSetting('programme-content', 'sortingState');
      if (sortingState !== undefined) {
        this.store.setModulesSorting(sortingState);
      }
    }

    // Layout
    const layoutState = this.loadSetting('programme-content', 'layoutState');
    if (layoutState !== undefined) {
      this.store.setModulesLayout(layoutState);
    }
  },

  watch: {
    programmeModulesReady() {
      if (this.programmeModulesReady) {
        this.$Loading.finish();
        this.$emit('modulesReady');
      }
    },
    programmeId() {
      this.$Loading.start();
      this.getProgrammeModules();
      this.getCompetencies();
    },
  },

  computed: {
    ready() {
      return this.programmeModulesReady && this.competenciesReady;
    },

    tabs() {
      return [
        {
          name: 'Curriculum',
          routeName: 'programme_curriculum',
          routeParams: {
            programmeId: this.programmeId,
          },
        },
        {
          name: 'Milestones',
          routeName: 'programme_milestones',
          routeParams: {
            programmeId: this.programmeId,
          },
        },
        {
          name: 'Competencies',
          routeName: 'programme_competencies',
          routeParams: {
            programmeId: this.programmeId,
          },
        },
      ];
    },

    currentTab() {
      return this.$route.name;
    },

    showTabs() {
      if (this.$featureFlags.isEnabled('kate-competencies-feature')) {
        return this.tabs;
      }
      return this.tabs.filter(tab => tab.name !== 'Competencies');
    },

    pageProps() {
      if (this.currentTab === 'programme_curriculum') {
        return {
          programmeId: this.programmeId,
          orderedModules: this.orderedModules,
          setModulesLayout: this.setModulesLayout,
        };
      } if (this.currentTab === 'programme_milestones') {
        return {
          programmeId: this.programmeId,
          orderedModules: this.orderedModules,
          setModulesLayout: this.setModulesLayout,
        };
      } if (this.currentTab === 'programme_competencies') {
        return {
          programmeId: this.programmeId,
          competencies: this.filteredCompetencies,
          setModulesLayout: this.setModulesLayout,
        };
      }
      return undefined;
    },

    programmeScheduleLink() {
      return `<a href="/schedule/programmes/${this.programmeId}">Schedule Page</a>`;
    },

    emptyProgramme() {
      return Boolean(this.programmeModules.length === 0 && this.programmeModulesReady);
    },

    orderedModules() {
      if (!this.programmeModules) return [];

      // Filter based on current tab
      let filteredModules = this.programmeModules.filter(m => {
        if (this.currentTab === 'programme_curriculum') {
          return m.track === 'curriculum';
        }
        if (this.currentTab === 'programme_milestones') {
          return m.track === 'milestone';
        }
        return false; // Default case
      });

      // Sort after filtering
      filteredModules = filteredModules.slice().sort(this.sortModule);

      // Apply descending order if needed
      return this.sortDescending ? filteredModules.reverse() : filteredModules;
    },

    sortDescending() {
      return this.store.sortModulesDescending;
    },

    setModulesLayout() {
      return this.store.isGridLayout;
    },

    emptyProgrammeText() {
      return `You can check the ${this.programmeScheduleLink} to see when each module will be released in this programme.
      <br>If you think there is an issue, please contact Cambridge Spark`;
    },

    emptyTrackTitle() {
      if (this.currentTab === 'programme_competencies') {
        return 'No competencies available in this programme';
      }
      return 'No modules available in this section';
    },

    emptyTrackText() {
      if (this.currentTab === 'programme_competencies') {
        return '';
      }
      return `You can check the ${this.programmeScheduleLink} to see when each module will be released in this programme.
      <br>To see modules in other tracks, select a different track from the tabs above`;
    },

    emptyIcon() {
      return 'fa-duotone fa-hourglass-start';
    },

    sortedCompetencies() {
      const sorted = [...this.competencies].sort((a, b) => {
        // First sort by category (Knowledge -> Skill -> Behaviour)
        const categoryOrder = { Knowledge: 1, Skill: 2, Behaviour: 3 };
        if (categoryOrder[a.category] !== categoryOrder[b.category]) {
          return categoryOrder[a.category] - categoryOrder[b.category];
        }

        // Then sort by number within the category
        const aNumber = parseInt(a.label.substring(1), 10);
        const bNumber = parseInt(b.label.substring(1), 10);
        return this.sortDescending ? bNumber - aNumber : aNumber - bNumber;
      });

      return sorted;
    },

    filteredCompetencies() {
      if (!this.sortedCompetencies) return [];
      return this.sortedCompetencies.filter(item => {
        // Apply category filter if any are active
        if (this.categoryFilterFunc) {
          const passesCategory = this.categoryFilterFunc(item);
          if (!passesCategory) return false;
        }
        // Apply text filter if any
        if (this.filterText) {
          const passesText = stringMatch(item.description, this.filterText, true)
            || stringMatch(item.label, this.filterText, true)
            || stringMatch(item.category, this.filterText, true)
            || stringMatch(this.parseTimestamp(item.latestScore), this.filterText, true);
          return passesText;
        }
        return true;
      });
    },
  },

  methods: {
    getProgrammeModules() {
      if (!this.programmeId) {
        return;
      }
      this.$logger.info('Getting modules for programme', {
        programmeId: this.programmeId,
      }, true);
      this.$http.get(`/api/curriculum/programmes/${this.programmeId}/modules`)
        .then(response => {
          this.programmeModules = response.data.modules;
          this.programmeModulesReady = true;
          this.$logger.info('Got modules for programme', {
            programmeId: this.programmeId,
          });
        })
        .catch(err => {
          this.programmeModules = [];
          if (this.$http.errIn(err, [404])) {
            this.$logger.warn('No modules found for programme', {
              programmeId: this.programmeId,
            });
            this.$ktoast.info('No modules were found for this programme', { position: 'top-center' });
          } else if (this.$http.isWarning(err)) {
            this.$logger.warn('Error while getting modules for programme', {
              programmeId: this.programmeId,
            }, err);
            this.showError(err, true);
          } else {
            this.$logger.error('Error while getting modules for programme', {
              programmeId: this.programmeId,
            }, err);
            this.showError(err, true);
          }
        })
        .then(() => {
          this.$Loading.finish();
          this.programmeModulesReady = true;
        });
    },

    getCompetencies() {
      this.$logger.info('Fetching competencies', { programmeId: this.programmeId }, true);
      this.$http.get(`/api/curriculum/programmes/${this.programmeId}/competencies/scores`)
        .then(response => {
          this.competencies = response.data.competencies;
          this.competenciesReady = true;
        })
        .catch(error => {
          this.$logger.error('Error fetching competencies', error);
        });
    },

    sortModule(a, b) {
      if (a.number && b.number) {
        return genericSort(a.number, b.number);
      }
      if (!a.number && !b.number) {
        return genericSort(a.name, b.name);
      }
      if (a.number && !b.number) {
        return -1;
      }
      return 1;
    },

    toggleSort() {
      this.store.toggleModulesSortingOrder();
      if (this.currentTab === 'programme_competencies') {
        this.saveSetting('programme-content', 'competenciesSortingState', this.sortDescending);
      } else {
        this.saveSetting('programme-content', 'sortingState', this.sortDescending);
      }
    },

    toggleLayout() {
      this.store.toggleModulesLayout();
      this.saveSetting('programme-content', 'layoutState', this.setModulesLayout);
    },

    filterCompetencies({ text, categoryFilter }) {
      this.filterText = text;
      this.categoryFilterFunc = categoryFilter || (() => true);
    },

    saveCompetency(competencyId, score) {
      this.$logger.info('Saving competency', { competencyId, score }, true);
      this.$http.post(`/api/curriculum/competencies/${competencyId}/scores`, { score })
        .then(response => {
          this.$logger.info('Competency saved', response.data, true);
          this.$ktoast.success('Competency score saved.');
          this.getCompetencies();
        })
        .catch(error => {
          this.$logger.error('Error saving competency', error);
        });
    },
  },
};
</script>
