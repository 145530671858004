<template>
  <div id="k-panel-competency-card" class="panel card-container flex-this">
    <div class="card-label">
      <span>{{ competency.label || '-' }}</span>
    </div>
    <div class="panel-body panel-card-container row">
      <div class="competency-card-info">
        <k-label :label="competency.category" />
        <p v-if="competency.description">{{ competency.description }}</p>
        <div class="completion-date">
          <span>Last Updated:
            <b v-if="competency.latestScore">{{ parseTimestamp(competency.latestScore)}}, ({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</b>
            <b v-else>No scores available</b>
          </span>
        </div>
      </div>
      <div class="competency-card-container">
        <div class="competency-card-actions">
          <button v-if="competency.scores.length > 1" class="btn btn-outlined" @click="openModal">View History</button>
          <button v-if="!editMode" class="btn btn-primary" @click="editCompetency">Submit New</button>
          <button v-if="editMode" class="btn btn-success" @click="saveCompetency">Save</button>
        </div>
        <div v-if="editMode" class="edit-mode-container">
          <p>Please select a score</p>
          <k-number-choice v-model="score" :value-max="9" size="small" />
        </div>
        <template v-else>
          <k-chart-bar v-if="competency.scores.length > 0" class="chart-bar"
            :yaxisMax="9"
            :seriesOne="[getLatestScore(competency.scores).score]"
            seriesOneTitle="Latest Scores"
            :xaxisData="[]"
            :tickAmount="9"
            :height="100"
            :showYAxisLabels="false"
          />
          <span class="no-scores-available" v-else>No scores available</span>
        </template>
      </div>
    </div>

    <k-modal v-if="showModal" @close="closeModal" :show="showModal">
      <template #header>
        <h2>{{ competency.label }} Score History</h2>
      </template>
      <template #body>
        <k-chart-line
          :yaxisMax="9"
          :xaxisData="competency.scores.map(score => parseTimestamp(score.submitted_at, undefined, 'do MMM yy')).reverse()"
          :seriesOne="competency.scores.map(score => score.score).reverse()"
          :seriesOneTitle="`${competency.label} Scores`"
          :stepSize="2"
          :height="300"
        />
      </template>
    </k-modal>
  </div>
</template>

<style>
#k-panel-competency-card .modal-body {
  min-height: 350px;
}

#k-panel-competency-card .chart-bar .apexcharts-inner.apexcharts-graphical {
  transform: translate(10px, 30px);
}

/* Grid view */
#competencies-track.grid-view .panel-card-container.row {
  flex-direction: column;
}

#competencies-track.grid-view .completion-date {
  margin: 0;
  max-width: unset;
}

#competencies-track.grid-view .competency-card-container {
  width: 100%;
  align-items: center;
}

#competencies-track.grid-view .no-scores-available {
  text-align: center;
}
</style>

<style scoped>
.no-scores-available {
  color: var(--kate-type-alt-dark);
  font-size: 1.2em;
  display: block;
  margin-top: 15px;
}

.panel-card-container.row {
  gap: 15px;
  align-items: flex-start;
}

.competency-card-info,
.competency-card-actions,
.edit-mode-container {
  display: flex;
  gap: 15px;
}

.competency-card-actions {
  justify-content: space-between;
}

.competency-card-actions:has(:only-child) {
  justify-content: flex-end;
}

.competency-card-info {
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.completion-date {
  margin: 0;
}

.edit-mode-container {
  flex-direction: column;
  gap: 5px;
}

.edit-mode-container p {
  margin: 0;
}
</style>

<script>
import TimeMixin from '../../mixins/time-mixins';
import KChartLine from '../../components/charts/k-chart-line.vue';
import KChartBar from '../../components/charts/k-chart-bar.vue';
import KNumberChoice from '../../components/k-number-choice.vue';
import KModal from '../../components/k-modal.vue';
import KLabel from '../../components/k-label.vue';

export default {
  components: {
    KChartLine,
    KChartBar,
    KNumberChoice,
    KModal,
    KLabel,
  },

  mixins: [TimeMixin],

  props: {
    competency: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
      inEditMode: false,
      score: null,
    };
  },

  computed: {
    editMode() {
      return this.inEditMode;
    },
  },

  methods: {
    getLatestScore(scores) {
      if (!scores.length) return { score: 0, submitted_at: '' };
      return scores.reduce((latest, score) => {
        if (!latest || score.submitted_at > latest.submitted_at) {
          return score;
        }
        return latest;
      });
    },
    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    editCompetency() {
      this.score = this.competency.scores[0]?.score || 0;
      this.inEditMode = true;
    },

    saveCompetency() {
      this.$emit('save-competency', this.score);
      this.inEditMode = false;
    },
  },
};
</script>
