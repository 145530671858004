<template>
  <ul v-if="hasAssets">
    <chapter-dropdown
      :defaultChapter="!isCustomChapter"
      :chapterDetails="chapterDetails"
      :chapterAssets="sortedAssets"
      :chapterProgress="chapterProgress"
    ></chapter-dropdown>
  </ul>
</template>

<style scoped>
ul {
  list-style: none;
  padding: 0;
}
</style>

<script>
import { sortObjectArray } from '../../modules/sort-by-object-property';
import SingleChapterMixin from '../../mixins/single-chapter-mixin';
import ChapterDropdown from './chapter-dropdown.vue';

export default {
  components: {
    ChapterDropdown,
  },

  mixins: [SingleChapterMixin],

  props: {
    chapter: {
      type: Object,
      required: true,
    },
  },

  computed: {
    chapterDetails() {
      return {
        name: this.chapter.name,
        label: this.chapter.label,
        number: this.chapter.number,
      };
    },

    sortedAssets() {
      if (this.chapter.name === 'Events') {
        return sortObjectArray(this.assets, 'event_start');
      }
      return this.assets;
    },
  },
};
</script>
