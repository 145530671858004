<template>
  <div id="chatbot-page">
    <chatbot-sidebar
      :chatHistory="chatHistory"
      :newChatButtonDisabled="newChatButtonDisabled"
      @select-chat="loadChatSession"
      @new-chat="newChat"
    />
    <main class="chatbot-main">
      <div class="panel panel-default content-with-spacing">
        <div class="panel-body">
          <div class="chatbot-welcome">
            <img src="../../../img/icons/kate-bot-chat-colours.svg" alt="KATE Logo" />
            <h2>KATE Learning Assistant</h2>
            <p v-html="welcomeMessage.text"></p>
          </div>
          <div v-if="hasChatHistory" class="chat-messages">
            <k-chat-msg
              v-for="(message, index) in messages"
              :key="message.chatbot_query_feedback_id || index"
              :message="message"
              :showActions="message.fromChatbot"
              @update-rating="(rating) => updateRating(index, rating)"
            />
          </div>
          <typing-loader v-if="isLoadingReply"></typing-loader>
          <form class="chatbot-input-container">
            <label for="chatbot-input">
              <textarea id="chatbot-input"
                class="form-control message-input"
                v-model="text"
                placeholder="Ask me anything..."
                rows="3"
                @keydown.enter="handleEnter"
                @input="autoExpand"
                ref="messageInput"
              ></textarea>
            </label>
            <button :disabled='!text.trim() || isDisabled' type="submit" class="btn btn-primary submit-button" @click.prevent="postQuery">Send</button>
          </form>
        </div>
      </div>
    </main>
  </div>
</template>

<style scoped>
#chatbot-page {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 310px;
  margin-top: 30px;
}

#chatbot-page main {
  padding: 15px 0;
  width: 100%;
}

.chatbot-welcome {
  text-align: center;
}

.chatbot-welcome p {
  text-align: left;
  margin: 20px 8px;
}

.chatbot-welcome img {
  width: 100px;
  height: 100px;
}

/* Chat history */
aside {
  padding: 15px;
}

/* Chat input */
.chatbot-input-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 25px;
}

textarea.message-input {
  margin: 0;
  resize: vertical;
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: unset;
  color: var(--kate-type-primary);
  flex-grow: 1;
}

.chatbot-input-container label,
.chatbot-input-container textarea {
  width: 100%;
  margin: 0;
}

/* Send button */
.submit-button {
  padding: 8px 40px;
}

.submit-button:hover:disabled,
.submit-button:disabled {
  color: var(--kate-disabled);
  background-color: transparent;
  text-decoration: none;
  font-weight: normal;
}

.submit-button:hover {
  text-decoration: underline;
  color: var(--kate-type-accent);
}
</style>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import StorageMixin from '../../mixins/storage-mixins';
import useGlobalStore from '../../stores/global';
import ChatbotSidebar from './chatbot-sidebar.vue';
import { uuid } from '../../modules/uuid-wrapper';
import KChatMsg from '../../components/chatbot/k-chat-msg.vue';
import TypingLoader from '../../components/chatbot/typing-loader.vue';

export default {
  mixins: [ErrorMixin, StorageMixin],

  components: {
    ChatbotSidebar,
    KChatMsg,
    TypingLoader,
  },

  data() {
    return {
      store: useGlobalStore(),
      text: '',
      chatbotResponse: undefined,
      welcomeMessage:
        {
          text: 'Hi there, I\'m KATE! I can answer questions about your programme, explain concepts, help you with a coding task and more. <br /> I\'m currently in Beta so please rate my responses to help me improve 🙂 <br /> What can I help you with today?',
          fromChatbot: true,
        },
      messages: [],
      isLoadingReply: false,
      isDisabled: false,
      sessionId: uuid(),
      chatHistory: [],
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getChatHistory();
  },

  computed: {
    hasChatHistory() {
      return this.messages.length > 0;
    },

    programmeId() {
      if (this.$route.params.programmeId === undefined || Number.isNaN(Number(this.$route.params.programmeId))) {
        return undefined;
      }
      return Number(this.$route.params.programmeId);
    },

    newChatButtonDisabled() {
      return !this.hasChatHistory || this.isLoadingReply;
    },
  },

  methods: {
    handleEnter(event) {
      if (event.shiftKey) {
        return; // Allow new line
      }
      event.preventDefault();
      this.postQuery();
    },

    getChatHistory() {
      this.$http.get('/api/chatbot/sessions').then(result => {
        this.chatHistory = result.data;
      }).catch(err => {
        this.$logger.error('Error getting chat history', err);
        this.chatHistory = [];
      }).then(() => {
        this.$Loading.finish();
      });
    },

    postQuery() {
      this.isDisabled = true;
      this.messages.push({ text: this.text, fromChatbot: false });
      this.$logger.info('Posting query to chatbot', { programmeId: this.programmeId }, true);
      const payload = {
        query: this.text,
        page_url: this.$route.path,
        session_id: this.sessionId,
        programme_id: this.programmeId || null,
        history: this.messages.slice(0, -1).map(message => ({
          source: message.fromChatbot ? 'chatbot' : 'human',
          content: message.text,
        })),
        module_id: null,
        module_asset_id: null,
        asset_type: null,
        user_rating: null,
      };
      this.isLoadingReply = true;
      this.text = '';

      this.$http.post(`/api/chatbot/query/${this.programmeId}`, payload).then(result => {
        this.chatbotResponse = result.data;
        this.messages.push({
          ...this.chatbotResponse,
          text: this.chatbotResponse?.answer,
          fromChatbot: true,
        });
        this.$logger.info('Successfully posted query', { programmeId: this.programmeId });
        this.getChatHistory();
      }).catch(err => {
        this.$logger.error('Error posting query', { programmeId: this.programmeId }, err);
        this.messages.push({
          text: 'There was an error getting a response from the Chatbot. Please try again later.',
          isError: true,
          fromChatbot: true,
        });
      }).then(() => {
        this.isLoadingReply = false;
        this.isDisabled = false;
      });
    },

    loadChatSession(selectedChat) {
      this.sessionId = selectedChat.session_id;
      this.$Loading.start();
      this.$http.get(`/api/chatbot/sessions/${this.sessionId}`).then(result => {
        this.messages = result.data.flatMap(item => [
          {
            text: item.query,
            fromChatbot: false,
          },
          {
            text: item.response,
            fromChatbot: true,
            chatbot_query_feedback_id: item.id,
            source_documents: item.source_documents,
            source_urls: item.source_urls,
            rating: item.user_rating,
          },
        ]);
      }).catch(err => {
        this.$logger.error('Error loading chat session', { sessionId: selectedChat.session_id }, err);
        this.messages = [];
      }).then(() => {
        this.$Loading.finish();
      });
    },

    updateRating(index, rating) {
      this.messages[index].rating = rating;
    },

    newChat() {
      this.messages = []; // This will trigger the messages watcher and set hasChatHistory to false
      this.sessionId = uuid(); // Generate new session ID
      this.text = ''; // Clear any existing input
      this.chatbotResponse = undefined; // Reset chatbot response
    },

    autoExpand(e) {
      const textarea = e.target;
      const maxHeight = 300;

      // Reset height to allow proper calculation
      textarea.style.height = 'auto';

      // Set new height
      const newHeight = Math.min(textarea.scrollHeight, maxHeight);
      textarea.style.height = `${newHeight}px`;

      // Add scrollbar if content exceeds max height
      textarea.style.overflowY = newHeight === maxHeight ? 'auto' : 'hidden';
    },
  },
};
</script>
