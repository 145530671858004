<template>
  <div v-if="crumblings.length > 1" class="content-with-spacing breadcrumb-container" :class="sidebarClass">
    <ul class="k-breadcrumb">
      <li v-for="crumb in crumblings">
        <router-link :to="crumb.path"
          :class="{active : crumb.active }">{{ crumb.text }}</router-link>
        <i class="fas fa-angle-right"></i>
      </li>
    </ul>
  </div>
</template>

<style>
.breadcrumb-container .k-breadcrumb li a > * {
  width: auto;
  line-height: 1;
  display: inline-block;
}
</style>

<style scoped>
.breadcrumb-container {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
}

.k-breadcrumb {
  list-style: none;
  text-transform: capitalize;
  padding: 10px 0 0;
}

.k-breadcrumb li {
  display: inline-block;
}

.k-breadcrumb li::after {
  content: " ";
  white-space: pre;
}

.k-breadcrumb li .active {
  pointer-events: none;
  cursor: default;
  color: var(--kate-type-primary);
  text-decoration: none;
}

.k-breadcrumb li a {
  text-decoration: underline;
  text-underline-offset: 2px;
  color: var(--kate-type-primary);
}

.k-breadcrumb li a:hover {
  text-decoration: underline;
  color: var(--kate-button-primary);
}

.k-breadcrumb li .fa-angle-right {
  padding: 0 4px;
  color: var(--kate-type-primary);
}

.k-breadcrumb li:last-child .fa-angle-right {
  display: none;
}

@media screen and (max-width: 767px) {
  .breadcrumb-container {
    position: relative;
  }

  .breadcrumb-container.sidebar-collapsed {
    left: 0;
  }
}
</style>

<script>
import SidebarMixin from '../mixins/sidebar-mixin';

export default {
  data() {
    return {
      crumblings: [],
    };
  },

  mixins: [SidebarMixin],

  beforeMount() {
    this.$crumbs.init(this.refresh);
  },

  methods: {
    refresh(crumbs) {
      this.crumblings = this.getCrumbs(crumbs);
    },
    getCrumbs(crumbs) {
      const output = [];
      if (!crumbs) {
        return output;
      }
      let crumb;
      for (let i = 0; i < crumbs.length; i++) {
        crumb = {
          path: crumbs[i].path || '',
          active: Boolean(crumbs[i].active),
          text: String(crumbs[i].text).replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;'),
        };
        output.push(crumb);
      }
      return output;
    },
  },
};
</script>
