<template>
  <div class="k-number-choice" :class="sizeClass">
    <ol class="number-range-container">
      <li v-for="number in range(valueMin, valueMax +1)" :key="`${number}-${$.uid}`">
        <label :for="`label-${number}-${$.uid}`" class="number-range-label">
          <input :name="`button-group-${$.uid}`"
            :id="`label-${number}-${$.uid}`"
            type='radio' v-model="selectedNumber" :value="number"
            :disabled="readOnly">
          <span class="btn number-button">{{ number }}</span>
        </label>
      </li>
    </ol>
  </div>
</template>

<style scoped>
.number-range-container {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 15px 0;
}

.number-range-container li {
  list-style: none;
  background-image: var(--number-range-gradient);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  border-radius: 4px;
}

.number-range-container label {
  margin: 0;
}

span.number-button {
  display: flex;
  padding: 0 5px;
  font-family: apercubold, sans-serif;
  color: var(--kate-type-dark);
  font-size: 1em;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.k-number-choice-medium span.number-button {
  width: 40px;
  height: 40px;
}

.k-number-choice-small span.number-button {
  width: 25px;
  height: 25px;
}

.k-number-choice-large span.number-button {
  width: 50px;
  height: 50px;
}

.number-range-container li .number-button:hover {
  background-color: rgb(255 238 90);
}

.number-range-label input {
  display: none;
}

.number-range-container li input:checked + .number-button {
  background-color: rgb(255 137 90);
  color: var(--kate-type-light);
  text-shadow: 1px 1px 2px var(--kate-type-dark);
}

</style>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
    },
    valueMin: {
      type: Number,
      default: 0,
    },
    valueMax: {
      type: Number,
      default: 10,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String, // 'small', 'medium', 'large'
      default: 'medium',
    },
  },

  data() {
    return {
      selectedNumber: this.modelValue,
    };
  },

  watch: {
    modelValue() {
      this.selectedNumber = this.modelValue;
    },

    selectedNumber() {
      this.emitAnswer();
    },
  },

  computed: {
    sizeClass() {
      return this.size ? `k-number-choice-${this.size}` : '';
    },
  },

  methods: {
    range(start, end, step = 1) {
      const output = [];
      for (let i = start; i < end; i += step) {
        output.push(i);
      }
      return output;
    },

    emitAnswer() {
      this.$emit('update:modelValue', this.selectedNumber);
    },
  },
};
</script>
