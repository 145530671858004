<template>
  <nav v-if="isLoggedIn" class="navbar navbar-expand-lg" role="navigation">
    <div class="container-fluid">
      <div class="navbar-header">
        <div class="navbar-brand navbar-brand-centered">
          <router-link :to="homeRoute"><img :src="kateLogoImage" alt="Edukate.ai - Programmes"/></router-link>
          <sidebar-toggle class="sidebar-toggle"></sidebar-toggle>
          <environment-banner></environment-banner>
        </div>
        <button type="button" aria-label="Navigation" title="Navigation" @click="toggleMobileNavbar"
                class="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu">
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div class="navbar-collapse" id="navbar-menu" :class="{ collapse : hideMobileNavbar }">
        <ul class="nav navbar-nav navbar-right" title="Navigation">
          <li v-if="!isDashboard && $featureFlags.isEnabled('kate-bot-full-screen')">
            <router-link :to="{ name: 'chatbot_page', params: { programmeId: $route.params.programmeId } }" class="chat-icon" title="Chat with KATE Learning Assistant">
              <img src="../../img/icons/kate-bot-chat-colours.svg" alt="KATE bot avatar"/> Ask KATE
            </router-link>
          </li>
          <k-theme-toggle title="Theme"></k-theme-toggle>
          <user-search-button v-if="isUserSearchAccessible && isDashboard"></user-search-button>
          <template v-if="!isDashboard">
            <li>
              <router-link :to="{ name: 'programme_page'}" title="Programmes">
                <i class="fas fa-chart-bar"></i>
                <span>Programmes</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'programme_schedule' }" title="Schedule">
                <i class="fa-duotone fa-calendars"></i>
                <span>Schedule</span>
              </router-link>
            </li>
          </template>
          <notification-dropdown
            @open="openNotificationDropdown"
            @close="closeNotificationDropdown"
            :isOpen="showNotificationDropdown"
            :isDashboard="isDashboard"
            :notifications="notifications"
            class="notification-dropdown"
          ></notification-dropdown>
          <support-dropdown
            @open="openSupportDropdown"
            @close="closeSupportDropdown"
            :isOpen="showSupportDropdown"
            class="support-dropdown"
          ></support-dropdown>
          <user-dropdown
            @open="openUserDropdown"
            @close="closeUserDropdown"
            :isOpen="showUserDropdown"
            class="user-dropdown">
          </user-dropdown>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style>
.sidebar-toggle {
  margin-left: 10px;
}

.chat-icon {
  cursor: pointer;
}

.chat-icon img {
  width: 20px;
  height: 20px;
}

/* ==== a. General  ==== */
nav.navbar {
  background-color: var(--kate-background);
  border-radius: 0;
  border: 0;
  color: var(--kate-type-primary);
  min-height: 70px;
  margin-bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
}

nav.navbar .content-with-spacing {
  padding-left: 0;
  padding-right: 0;
}

nav.navbar > div {
  display: flexbox;
  display: flex;
  align-items: center;
}

.navbar-nav a,
.navbar-nav button,
.navbar-header .navbar-brand.navbar-brand-centered {
  cursor: pointer;
  text-decoration-color: transparent;
}

.navbar-nav button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.navbar-group-change-button {
  cursor: pointer;
}

.navbar-group-change-button:hover {
  color: var(--kate-primary);
}

.navbar-nav .k-dropdown-content {
  right: 0; /* Align right */
  top: 68px;
}

.navbar-nav > li > div {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0.6em;
}

.navbar-nav > li > div.k-dropdown,
nav.navbar .navbar-collapse {
  padding-right: 0;
}

.k-navbar-options-button {
  width: 100%;
}

.navbar .k-group-button {
  border: none;
  background-color: transparent;
}

.navbar li.profile-page {
  position: relative;
}

.closing-container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.closing-container.hide-me {
  display: none;
}

/* ==== b. Brand Logo ==== */
.navbar .navbar-header {
  display: flexbox;
  display: flex;
}

nav.navbar > .container-fluid .navbar-brand {
  position: inherit;
  padding: 0;
  margin-left: 0;
  display: flex;
  align-items: center;
}

.navbar-brand-centered > a {
  display: flex;
  height: 100%;
  align-items: center;
}

.navbar-brand-centered img {
  height: 40px;
  margin-left: -9px;
}

/* ==== c.Nav Links ==== */
#navbar-menu {
  flex: 1;
}

nav.navbar ul.module-list li a,
nav.navbar ul.module-list li button,
nav.navbar div ul.nav li a,
nav.navbar div ul.nav li > button,
.notification-dropdown-btn > i {
  font-size: 1em;
  line-height: 38px;
  padding: 15px;
}

nav.navbar div ul.nav li a,
nav.navbar div ul.nav li > button {
  font-family: hk-grotesk-light, sans-serif;
}

nav.navbar div ul.nav li ul button {
  background-color: transparent;
  width: 100%;
}

nav.navbar div ul.nav li ul .show-more > button {
  background-color: var(--kate-button-primary);
}

nav.navbar div ul.nav li ul .show-more > button:hover {
  background-color: var(--kate-button-primary-hover);
}

nav.navbar div ul.nav li button.modal-default-button {
  line-height: normal;
  background-color: transparent;
  border: 1px solid var(--kate-type-primary);
  color: var(--kate-type-primary);
}

.navbar-nav .k-dropdown .k-dropdown-content a i,
nav.navbar div ul.nav li a i {
  padding-right: 5px;
}

.user-dropdown .user-avatar,
nav.navbar div .nav-link {
  padding: 15px;
}

.navbar-nav .k-dropdown .k-dropdown-content a.k-label:hover,
nav.navbar div .nav-link:hover,
nav.navbar div ul.nav li button:hover,
nav.navbar div ul.nav li a:hover {
  background-color: var(--kate-background-alpha);
  border-radius: 0;
  color: var(--kate-type-light);
  font-family: hk-grotesk, sans-serif;
}

nav.navbar div ul.nav li button.modal-default-button:hover {
  background-color: var(--kate-type-primary);
  color: var(--kate-type-dark);
  border-radius: 8px;
}

nav.navbar div ul.nav li a:focus {
  background-color: var(--kate-panel-alt);
}

nav.navbar div ul.nav li a.a-exact-active {
  color: var(--kate-secondary);
  text-decoration: underline;
}

.navbar-nav.navbar-right,
.nav.navbar-nav {
  margin-right: 0;
  display: flexbox;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.navbar-nav .k-dropdown .k-dropdown-content {
  background-color: var(--kate-background-alt);
  border-radius: 3px;
  padding: 5px 0;
}

.navbar-nav .k-dropdown .k-dropdown-content a.k-label {
  background-color: transparent;
  padding: 10px;
  margin: 0;
  border-top: 1px solid var(--kate-panel-alt);
}

.navbar-nav .k-dropdown .k-dropdown-content li:first-child a {
  border-top: 0;
}

.navbar .profile-page a,
#logout-button {
  cursor: pointer;
}

#navbar-avatar {
  width: 2.28em; /* 1.8em works nicely too */
  height: 2.28em; /* 1.8em works nicely too */
  margin-right: 3px;
  margin-left: 10px;
}

.navbar ul.dropdown.is-open {
  opacity: 1;
  transform: translateY(70px);
  z-index: 10;
  display: block;
  pointer-events: unset;
  box-shadow: var(--box-shadow);
  min-width: 135px;
}

.navbar .support-dropdown ul.dropdown,
.navbar .notification ul.dropdown {
  min-width: 205px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.navbar .notification ul.dropdown {
  max-height: 30vh;
  overflow: auto;
}

.navbar .notification ul.dropdown.is-open,
.navbar .support-dropdown ul.dropdown.is-open {
  transform: translate(-50%, 70px);
}

.navbar ul.is-open.non-admin.dashboard-dropdown {
  bottom: -52px;
}

.navbar ul.dropdown.dashboard-dropdown {
  min-width: 155px;
}

.navbar ul.dropdown {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 0;
  list-style: none;
  display: block;
  opacity: 0;
  transform: translateY(0%);
  transition: all 0.3s ease-in-out;
  z-index: 1;
  background-color: var(--kate-background-alt);
  pointer-events: none;
  border-radius: 3px;
}

.navbar .user-avatar + ul.dropdown {
  min-width: 200px;
}

.navbar .support a + ul.dropdown {
  left: -50%;
  min-width: 300px;
}

.navbar .notification a + ul.dropdown {
  left: -165%;
  min-width: 300px;
  max-height: 500px;
  overflow-y: auto;
}

.navbar ul.dropdown > li {
  border-bottom: 1px solid var(--kate-panel-alt);
}

.navbar ul.dropdown > li:last-child {
  border-bottom: 0;
}

span.dropdown-container-arrow,
.navbar .dropdown::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -6px;
  margin-top: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent var(--kate-background-alt) transparent;
}

span.dropdown-container-arrow {
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
}

.is-open span.dropdown-container-arrow {
  opacity: 1;
  transform: translate(-50%, 63px);
}

.navbar .dropdown li a {
  display: block;
  padding: 10px;
  margin: 0 !important;
}

.navbar .dropdown .user-id {
  padding: 10px 15px;
  color: var(--kate-type-primary);
  pointer-events: none;
}

/* ==== f. Mobile View ==== */
nav.navbar .navbar-toggle,
nav.navbar .navbar-toggle:hover,
nav.navbar .navbar-toggle:focus {
  background-color: transparent;
  border: 0;
  border-color: transparent;
}

.navbar-toggle {
  font-size: 2em;
  color: var(--kate-primary);
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  transition: all 0.3s;
}

.navbar-toggle:hover {
  color: var(--kate-primary-dark);
}

nav.navbar .navbar-collapse {
  border-color: var(--kate-background-body);
  text-align: center;
}

nav.navbar .navbar-header button[aria-expanded="true"] {
  width: unset;
}

/* media */
@media (min-width: 768px) and (max-width: 1200px) {
  .navbar .navbar-header {
    width: 25%;
    flex: unset;
  }

  #navbar-menu {
    width: 55%;
  }
}

/* TODO: Revert to 918px when theme toggle moved to user dropdown.  */
/* @media (min-width: 768px) and (max-width: 918px) { */
@media (min-width: 768px) and (max-width: 1084px) {
  nav.navbar {
    display: flex;
    justify-content: space-between;
  }

  nav.navbar .container-fluid {
    margin: 0;
    width: 100%;
  }

  .navbar .nav > li {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .navbar .nav > li.profile-page {
    width: 100%;
  }

  .navbar .nav > li > a > span,
  .navbar .nav > li > button > span {
    display: none;
  }

  nav.navbar ul.module-list li a,
  nav.navbar ul.module-list li button,
  nav.navbar div ul.nav li a,
  nav.navbar div ul.nav li > button,
  .notification-dropdown-btn > i {
    display: block;
  }

  .navbar .nav > li > a,
  .navbar .nav > li > button {
    width: 100%;
    min-width: 50px;
    height: 100%;
    text-align: center;
    padding: 10px;
  }

  .navbar .nav > li > a i {
    font-size: 1.2em;
    padding: 0 !important;
  }

  .navbar .nav > li.profile-page > a i {
    margin-right: 0;
  }

  .support-dropdown-btn .fa-caret-down {
    display: none;
  }

  /* Dropdowns */
  .navbar .support a + ul.dropdown {
    left: -130px;
  }

  .navbar .notification a + ul.dropdown,
  .navbar .user-avatar + ul.dropdown,
  .navbar .support a + ul.dropdown {
    transform: translateY(60px);
  }

  .navbar .dropdown::before {
    left: 50%;
  }
}

@media (max-width: 767px) {
  .navbar-brand-centered img {
    height: 30px;
    margin-left: 0;
  }

  nav .navbar-toggle {
    padding: 0 15px;
    width: 90px;
    font-size: 1.75em;
  }

  nav.navbar > div {
    display: block;
    padding: 0;
  }

  .navbar-header {
    margin: 0 !important;
    width: 100% !important;
    padding: 10px 0;
  }

  .navbar-brand {
    flex: 1;
  }

  #navbar-menu {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .navbar-nav li > button {
    width: 100%;
  }

  .navbar-collapse .navbar-nav {
    margin-top: 10px;
  }

  .navbar-collapse .navbar-nav.navbar-right {
    margin-right: 0;
    margin-left: 0;
    flex-direction: column;
  }

  .navbar-collapse .navbar-nav > li {
    width: 100%;
  }

  .navbar-collapse .navbar-nav .k-dropdown {
    width: 100%;
    padding: 10px 0;
  }

  .navbar-collapse .navbar-nav .k-dropdown a {
    padding: 10px 0;
  }

  .navbar-collapse .navbar-nav .k-dropdown-content {
    position: unset;
    margin-top: 10px;
  }

  .navbar ul.dropdown.is-open {
    transform: translateY(0);
    display: block;
  }

  .navbar ul.dropdown {
    position: unset;
    display: none;
  }
}
</style>

<script>
import useGlobalStore from '../stores/global';
import useThemeStore from '../stores/theme';
import SupportDropdown from './navbar-dropdowns/support-dropdown.vue';
import UserDropdown from './navbar-dropdowns/user-dropdown.vue';
import NotificationDropdown from './navbar-dropdowns/notification-dropdown.vue';
import SidebarToggle from './sidebar-toggle.vue';
import KThemeToggle from './k-theme-toggle.vue';
import EnvironmentBanner from './environment-banner.vue';
import NotificationMixin from '../mixins/notification-mixin';
import { importImage } from '../modules/dynamic-image-importer';
import UserSearchButton from './user-search-button.vue';

export default {
  components: {
    UserSearchButton,
    SupportDropdown,
    UserDropdown,
    NotificationDropdown,
    SidebarToggle,
    KThemeToggle,
    EnvironmentBanner,
  },

  mixins: [NotificationMixin],

  data() {
    return {
      store: useGlobalStore(),
      themeStore: useThemeStore(),
      hideMobileNavbar: true,
      showUserDropdown: false,
      showSupportDropdown: false,
      showNotificationDropdown: false,
    };
  },

  mounted() {
    if (this.isLoggedIn) {
      this.startNotificationRefreshCycle();
      this.getNotifications();
    }
  },

  beforeUnmount() {
    if (this.notificationRefreshInterval !== undefined) {
      clearInterval(this.notificationRefreshInterval);
    }
  },

  watch: {
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.getNotifications();
        this.startNotificationRefreshCycle();
      }
      if (!this.isLoggedIn) {
        // Kill the refresh cycle on logout
        clearInterval(this.notificationRefreshInterval);
      }
    },
  },

  computed: {
    isUserSearchAccessible() {
      return (this.store.userPermissions.includes('view_all_learners') || this.store.userPermissions.includes('view_restricted_learners'));
    },
    isDashboard() {
      return this.store.isDashboard;
    },
    isEmployer() {
      return this.store.isEmployer;
    },
    isLoggedIn() {
      return this.store.isLoggedIn;
    },
    route() {
      return this.$route;
    },
    homeRoute() {
      if (this.isDashboard) {
        if (this.isEmployer) {
          return { name: 'employer_learner_summary' };
        }
        return { path: '/' };
      }
      return { name: 'programme_page' };
    },
    kateLogoImage() {
      if (this.themeStore.theme !== 'light') {
        return importImage('kate_full_logo.svg');
      }
      return importImage('kate_full_logo_dark.svg');
    },
  },

  methods: {
    toggleMobileNavbar() {
      this.hideMobileNavbar = !this.hideMobileNavbar;
    },

    openSupportDropdown() {
      this.showSupportDropdown = true;
      this.showUserDropdown = false;
      this.showNotificationDropdown = false;
    },

    openUserDropdown() {
      this.showUserDropdown = true;
      this.showSupportDropdown = false;
      this.showNotificationDropdown = false;
    },

    openNotificationDropdown() {
      this.showNotificationDropdown = true;
      this.showUserDropdown = false;
      this.showSupportDropdown = false;
    },

    closeNotificationDropdown(readNotifications) {
      this.showNotificationDropdown = false;
      if (readNotifications) {
        // Mark unread notifications as read
        for (let i = 0; i < this.notifications.length; i++) {
          if (!this.notifications[i].read_at) {
            const x = this.notifications[i];
            x.read_at = new Date();
            this.notifications.splice(i, 1, x);
            this.readNotification(x.notification_id);
          }
        }
      }
    },

    closeSupportDropdown() {
      this.showSupportDropdown = false;
    },

    closeUserDropdown() {
      this.showUserDropdown = false;
    },
  },
};
</script>
