<template>
  <div v-if="assets.length" class="chapter-container panel panel-default">
    <div class="category-sub-header">
      <div class="chapter-header">
        <h3>
          <b v-if="isCustomChapter" class="number">{{ chapter.number }}.</b>
          <i v-else class="fas k-asset-icon" :class="getDefaultChapterIcon(chapter.name)"></i>
          <b class="title">{{ chapter.name }}</b>
        </h3>
        <div v-if="isLearningUnits" class="category-text">
          <p class="steps-tooltip">To get started you can click a <b>Learning Unit</b> to explore the content in your browser,
            or click <b>download</b> to get a zipped version of the content
          <span class="question-icon">
            <k-tooltip
            text="Once downloaded, to extract the zipped folder, simply double-click it (on Mac) or right-click > Extract Files > Select Destination path of your choosing > Ok">
              <sup><i class="fas fa-question-circle"></i></sup>
            </k-tooltip>
          </span>
          and start working locally</p>
        </div>
        <k-asset-counts
          :assets="assets"
          :border="true"
        ></k-asset-counts>
      </div>
      <button @click="showSectionContent = !showSectionContent" class="btn-icon section-toggle" aria-label="Toggle section content" title="Toggle section content">
        <i class="toggle fas" :class="toggleClass"></i>
      </button>
    </div>
    <k-progress v-if="mandatoryAssets.length > 0"
      shape="bar"
      :percentage="chapterProgress"
      :precision="1"
      :show-bar-text="true"
      :valueMax="chapter.assets.length"
      customText="Completed"
      progress-theme="dark"
      ariaLabel="Overall Chapter Completion"
      size="small"
    ></k-progress>
    <hr v-if="showSectionContent"/>
    <div class="assets-details-container" v-if="showSectionContent">
      <ol>
        <template v-for="asset in sortedAssets">
          <asset-lu
            v-if="asset.asset_type === 'learning_unit'"
            :learning-unit="asset"
            :key="asset.id"
            :module-id="moduleId"/>
          <asset-video
            v-if="asset.asset_type === 'video'"
            :video="asset"
            :key="asset.id"
            :module-id="moduleId"/>
          <asset-slide
            v-if="asset.asset_type === 'slide_deck'"
            :slides="asset"
            :key="asset.id"
            :module-id="moduleId"/>
          <asset-quiz
            v-if="asset.asset_type === 'quiz'"
            :quiz="asset"
            :key="asset.id"
            :module-id="moduleId"/>
          <asset-pak
            v-if="asset.asset_type === 'pak'"
            :pak="asset"
            :key="asset.id"
            :latest-score="asset.latest_score"/>
          <asset-calendar-event
            v-if="asset.asset_type === 'calendar_event'"
            :calendar-event="asset"
            :key="asset.id"
            :module-id="moduleId"/>
          <asset-meeting
            v-if="asset.asset_type === 'meeting'"
            :meeting="asset"
            :key="asset.id"
            :module-id="moduleId"/>
          <asset-markdown-page
            v-if="asset.asset_type === 'markdown_page'"
            :markdown-page="asset"
            :key="asset.id"
            :module-id="moduleId"/>
          <asset-questionnaire
            v-if="asset.asset_type === 'questionnaire'"
            :questionnaire="asset"
            :key="asset.id"
            :module-id="moduleId"/>
        </template>
      </ol>
    </div>
  </div>
</template>

<style scoped>
.category-sub-header,
.chapter-container {
  display: flex;
  display: flexbox;
  flex-wrap: wrap;
}

.chapter-header h3 {
  padding-bottom: 15px;
}

.chapter-container hr {
  width: 100%;
  margin: 1px 0 0;
  border-color: var(--kate-background-body-alpha);
}

.category-sub-header {
  width: 100%;
  padding: 15px;
  font-size: 1.3em;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.category-sub-header h3 > i.fas {
  margin-left: 0;
  margin-right: 15px;
}

.category-sub-header button.section-toggle i.toggle {
  font-size: 1.5rem;
  padding-left: 20px;
}

.category-sub-header:hover i.toggle {
  transition: all 0.3s ease;
  color: var(--kate-button-secondary);
}

.category-text {
  width: 100%;
  padding: 15px 0 0;
  font-size: 0.77em;
}

.question-icon sup {
  cursor: pointer;
  color: var(--kate-button-primary);
}

.assets-details-container {
  padding: 15px;
  margin-top: 15px;
}

.assets-details-container > ol {
  padding: 0;
  flex-wrap: nowrap;
  position: relative;
  align-items: center;
}

.assets-details-container > ol .download-button a {
  padding: 10px;
}

</style>

<style>
ul.k-asset-count li {
  font-size: 1rem;
}

.k-progress.squared .progress {
  border-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.assets-details-container ol li {
  display: flex;
  display: flexbox;
  list-style: none;
  align-items: center;
  margin-bottom: 20px;
  min-height: 66px;
  gap: 18px;
}

.assets-details-container ol li:last-child {
  margin-bottom: 0;
}

.assets-details-container > ol li a {
  color: var(--kate-type-light);
}

.assets-details-container > ol li .download-button a {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.assets-details-container > ol li .download-button a i {
  font-size: 1rem;
}

.assets-details-container > ol li a.view-content {
  color: var(--kate-type-primary);
  border-color: var(--kate-type-primary);
  font-weight: 100;
  font-size: 0.85em;
}

.assets-details-container > ol li.completed a.view-content {
  color: var(--kate-type-primary);
}

.assets-details-container > ol li a.view-content:hover,
.assets-details-container > ol li.completed a.view-content:hover {
  color: var(--kate-type-dark);
}

.assets-details-container ol li .k-asset-icon {
  width: 60px;
  height: 60px;
  padding: 5px;
  display: flex;
  display: flexbox;
  font-size: 1.2em;
  border-radius: 50%;
  position: relative;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  border: var(--border-secondary);
}

.assets-details-container .asset-due-date {
  font-size: 0.85em;
  display: block;
}

.chapter-container .k-progress {
  padding: 0 15px 15px;
}

/* Icons colours */
.assets-details-container ol li.completed > a,
.assets-details-container ol li.completed .asset-details > a,
.assets-details-container ol li.completed .fas.k-asset-icon,
.assets-details-container ol li.completed .fa-check {
  color: var(--kate-success-alt);
}

.assets-details-container ol li.completed .fas.k-asset-icon {
  border-color: var(--kate-success-alt) !important;
}

.assets-details-container ol li .fas.k-asset-icon::after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: -27px;
  height: 26px;
  width: 3px;
  background-color: var(--kate-background-body);
}

.assets-details-container > ol li:last-child .fas.k-asset-icon::after,
.assets-details-container > ol .download-button i::after {
  content: "";
  display: none;
  bottom: 0;
  height: 0;
  width: 0;
}

@media (max-width: 767px) {
  .assets-details-container ol li {
    flex-wrap: wrap;
  }
}
</style>

<script>
import { sortObjectArray } from '../modules/sort-by-object-property';
import AssetLu from './asset-lu.vue';
import AssetVideo from './asset-video.vue';
import AssetSlide from './asset-slides.vue';
import AssetQuiz from './asset-quiz.vue';
import AssetPak from './asset-pak.vue';
import AssetCalendarEvent from './asset-calendar-event.vue';
import KTooltip from '../components/k-tooltip.vue';
import KProgress from '../components/k-progress.vue';
import KAssetCounts from '../components/k-asset-counts.vue';
import SingleChapterMixin from '../mixins/single-chapter-mixin';
import AssetIconMixin from '../mixins/asset-icon-mixin';
import AssetMeeting from './asset-meeting.vue';
import AssetMarkdownPage from './asset-markdown-page.vue';
import AssetQuestionnaire from './asset-questionnaire.vue';

export default {
  components: {
    AssetMeeting,
    'asset-lu': AssetLu,
    'asset-video': AssetVideo,
    'asset-slide': AssetSlide,
    'asset-quiz': AssetQuiz,
    'asset-pak': AssetPak,
    'asset-calendar-event': AssetCalendarEvent,
    'asset-meeting': AssetMeeting,
    'asset-markdown-page': AssetMarkdownPage,
    'asset-questionnaire': AssetQuestionnaire,
    KTooltip,
    KProgress,
    KAssetCounts,
  },

  mixins: [SingleChapterMixin, AssetIconMixin],
  props: {
    moduleId: {
      type: Number,
    },
    chapter: {
      type: Object,
    },
  },

  computed: {
    sortedAssets() {
      if (this.chapter.name === 'Events') {
        return sortObjectArray(this.assets, 'event_start');
      }
      return this.assets;
    },
  },
};

</script>
