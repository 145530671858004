<template>
  <aside>
    <h2>Chatbot History</h2>
    <div class="search-controls">
      <k-text-search v-model="filterText"/>
      <button class="btn btn-primary new-chat-button" @click="newChat" :disabled="newChatButtonDisabled">
        <i class="fa fa-plus"></i> New Chat
      </button>
    </div>
    <ul class="chat-history-list" v-if="chatHistory.length > 0">
      <chatbot-history-item v-for="chatHistoryItem in filteredChatHistory"
        :key="chatHistoryItem.id"
        :chatHistory="chatHistoryItem"
        :selectedChatHistoryId="chatHistoryItem.id"
        @select-chat="$emit('select-chat', $event)"
      />
    </ul>
  </aside>
</template>

<style scoped>
aside {
  margin-top: 70px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--kate-sidebar-background);
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100% - 70px);
  z-index: 999;
  padding: 0 0 15px;
  width: 310px;
  transition: width 0.2s ease-in-out;
  white-space: nowrap;
  box-shadow: var(--box-shadow);
}

.chat-history-list {
  list-style: none;
  padding: 0;
}

.chat-history-item {
  margin: 15px 0;
}

.new-chat-button {
  margin-top: 15px;
  width: 100%;
}

</style>

<script>
import ChatbotHistoryItem from './chatbot-history-item.vue';
import KTextSearch from '../../components/k-text-search.vue';
import stringMatch from '../../modules/string-match';

export default {
  components: {
    ChatbotHistoryItem,
    KTextSearch,
  },

  props: {
    chatHistory: {
      type: Array,
      default: () => [],
    },
    newChatButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filterText: '',
    };
  },

  emits: ['new-chat'],

  computed: {
    filteredChatHistory() {
      if (!this.chatHistory) return [];
      return this.chatHistory.filter(item => stringMatch(item.query, this.filterText, true));
    },
  },

  methods: {
    newChat() {
      this.$emit('new-chat');
    },
  },
};
</script>
