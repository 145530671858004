<template>
  <div class="actions-container flex-this">
    <template v-if="currentTab === 'programme_competencies'">
      <k-text-search v-model="filterText" inputStyle="alt"></k-text-search>
      <k-filters
        ref="filters"
        :filter-options="categoryFilters"
        :default-settings="defaultFilters"
        @filter="handleCategoryFilter"
      />
    </template>
    <button class="btn btn-outlined" aria-label="Sort" title="Sort" @click="$emit('toggleSort')">
      Sort <i class="fas" :class="sortDescending ? 'fa-sort-amount-up' : 'fa-sort-amount-down-alt'"></i>
    </button>
    <button class="btn btn-outlined" aria-label="Toggle layout" title="Toggle layout" @click="$emit('toggleLayout')">
      <i class="fa-solid" :class="setModulesLayout ? 'fa-list' : 'fa-grid-2'"></i>
    </button>
  </div>
</template>

<style scoped>
.actions-container {
  gap: 10px;
}
</style>

<script>
import KTextSearch from '../../components/k-text-search.vue';
import KFilters from '../../components/k-filters.vue';

export default {
  components: {
    KTextSearch,
    KFilters,
  },

  props: {
    currentTab: {
      type: String,
      default: 'programme_curriculum',
    },
    sortDescending: {
      type: Boolean,
      default: false,
    },
    setModulesLayout: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filterText: '',
      lastFilterFunc: () => true,
      categoryFilters: [
        {
          key: 'Knowledge',
          text: 'Knowledge (K)',
          func: item => item.category === 'Knowledge',
        },
        {
          key: 'Skill',
          text: 'Skills (S)',
          func: item => item.category === 'Skill',
        },
        {
          key: 'Behaviour',
          text: 'Behaviour (B)',
          func: item => item.category === 'Behaviour',
        },
      ],
      defaultFilters: {
        Knowledge: false,
        Skill: false,
        Behaviour: false,
      },
    };
  },

  watch: {
    filterText() {
      this.emitFilters();
    },
  },

  methods: {
    handleCategoryFilter() {
      const combinedFilter = item => {
        const activeFilters = this.$refs.filters.$data.activeFilters;
        return this.categoryFilters.some(filter => activeFilters[filter.key] && filter.func(item));
      };

      this.lastFilterFunc = combinedFilter;
      this.emitFilters();
    },

    emitFilters() {
      if (!this.categoryFilters.some(filter => this.$refs.filters.$data.activeFilters[filter.key])) {
        this.$emit('filter', {
          text: this.filterText,
          categoryFilter: () => true,
        });
        return;
      }

      this.$emit('filter', {
        text: this.filterText,
        categoryFilter: this.lastFilterFunc,
      });
    },
  },
};
</script>
