import ProjectOverview from '../../../feedback/project-over.vue';
import ProfileOverview from '../../../profile/profile-overview.vue';
import ProfileLanding from '../../../profile/profile-landing.vue';
import ProfileInformation from '../../../profile/profile-information.vue';
import ProfileEmailAddress from '../../../profile/profile-email-address.vue';
import ProfilePassword from '../../../profile/profile-password.vue';
import ProfileQuestions from '../../../profile/profile-questions.vue';
import ProfileAchievements from '../../../profile/profile-achievements.vue';
import ProfileGitSettings from '../../../profile/profile-git-settings.vue';
import ManageKodsPage from '../../../profile/kods.vue';
import ChangePasswordPage from '../../../profile/change-password-page.vue';
import MainHelpPage from '../../../help/main-help-page.vue';
import KateIDE from '../../../ide/k-ide.vue';
import LoginPage from '../../../profile/login.vue';
import VerifyEmailPage from '../../../profile/verify-email.vue';
import NotFound from '../../../layout/not-found.vue';
import AdvancedSetup from '../../../help/advanced-setup.vue';
import PolicyPage from '../../../policy/policy.vue';
import PolicyAcceptPage from '../../../policy/policy-acceptance.vue';
import FingerprintPage from '../../../help/public-keys.vue';
import FeedbackInstructions from '../../../feedback/feedback-instructions.vue';
import FeedbackAppWrapper from '../../wrappers/kate_app/feedback-app-wrapper.vue';
import FeedbackNotebook from '../../../notebook/notebook.vue';
import ProgrammePage from '../../../programmes/programme-page.vue';
import MaintenancePage from '../../../layout/maintenance.vue';
import ModuleLu from '../../../module_pages/module-lu.vue';
import ModuleLanding from '../../../module_pages/module-landing.vue';
import ModuleOverview from '../../../module_pages/module-overview.vue';
import ModuleVideo from '../../../module_pages/module-video.vue';
import ModuleSlides from '../../../module_pages/module-slides.vue';
import CalendarEventPage from '../../../module_pages/module-calendar-event.vue';
import MeetingPage from '../../../module_pages/module-meeting.vue';
import ModuleMarkdownPage from '../../../module_pages/module-markdown-page.vue';
import ModuleQuestionnaire from '../../../questionnaires/questionnaire-page.vue';
import QuizPage from '../../../quizzes/quiz-page.vue';
import FeedbackLeaderboard from '../../../feedback/leaderboard.vue';
import ModuleKnowledgeBase from '../../../module_pages/module-knowledge-base.vue';
import ThreadPage from '../../../knowledge_base/thread-page.vue';
import SpinKodPage from '../../../profile/kod-loading.vue';
// Programme pages
import Competencies from '../../../programmes/competencies.vue';
import Curriculum from '../../../programmes/curriculum.vue';
import Milestones from '../../../programmes/milestones.vue';
import ProgrammeSchedule from '../../../programmes/programme-schedule.vue';
import UserNotInProgramme from '../../../programmes/user-not-in-programme.vue';
// Chatbot pages
import ChatbotPage from '../../../programmes/chatbot/chatbot-page.vue';

function getModulePagePakKey(routeDetails) {
  return `pak_ov_${routeDetails.params.modulePakId}`;
}

const routes = [
  {
    path: '/login',
    component: LoginPage,
    name: 'login_page',
    kate_sidebar_disabled: true,
    kate_is_public: true,
  },
  {
    path: '/login_token',
    component: LoginPage,
    name: 'login_gl_page',
    props: {
      withGitlabToken: true,
    },
    kate_sidebar_disabled: true,
    kate_is_public: true,
  },
  {
    path: '/email_verification/:verificationType?',
    component: VerifyEmailPage,
    name: 'email_verification_page',
    kate_sidebar_disabled: true,
    kate_is_public: true,
  },
  {
    path: '/programmes/:programmeId?/:track?',
    alias: '/',
    component: ProgrammePage,
    name: 'programme_page',
    meta: {
      prettyName: 'Programme Page',
      show: true,
    },
    children: [
      {
        path: 'curriculum',
        component: Curriculum,
        name: 'programme_curriculum',
        kate_sidebar_disabled: true,
      },
      {
        path: 'milestone',
        component: Milestones,
        name: 'programme_milestones',
        kate_sidebar_disabled: true,
      },
      {
        path: 'competencies',
        component: Competencies,
        name: 'programme_competencies',
        kate_sidebar_disabled: true,
      },
    ],
    kate_sidebar_disabled: true,
  },
  {
    path: '/programmes/:programmeId/chat',
    component: ChatbotPage,
    name: 'chatbot_page',
    kate_sidebar_disabled: true,
  },
  {
    path: '/programmes/:programmeId?/schedule',
    component: ProgrammeSchedule,
    name: 'programme_schedule',
    kate_sidebar_disabled: true,
  },
  {
    path: '/modules/:moduleId',
    component: ModuleOverview,
    children: [
      {
        path: '',
        component: ModuleLanding,
        name: 'module_landing',
      },
      {
        path: 'video/:moduleVideoId',
        component: ModuleVideo,
        name: 'module_video',
        meta: {
          moduleKey: () => 'module_video', // Don't remount ModuleVideo component if moduleVideoId changes
        },
      },
      {
        path: 'slides/:moduleSlidesId',
        component: ModuleSlides,
        name: 'module_slides',
      },
      {
        path: 'lu/:moduleLuId/',
        component: ModuleLu,
        name: 'module_lu',
      },
      {
        path: 'pak/:modulePakId',
        component: ProjectOverview,
        children: [
          {
            path: '',
            alias: 'instructions',
            component: FeedbackInstructions,
            name: 'pak_ov_instructions',
            meta: {
              moduleKey: getModulePagePakKey,
            },
          },
          {
            path: 'ide',
            component: KateIDE,
            name: 'pak_ov_web_ide',
            meta: {
              moduleKey: getModulePagePakKey,
            },
          },
          {
            path: 'feedback',
            component: FeedbackAppWrapper,
            name: 'pak_ov_feedback',
            meta: {
              moduleKey: getModulePagePakKey,
            },
          },
          {
            path: 'solution',
            component: KateIDE,
            name: 'pak_ov_ide_solution',
            meta: {
              moduleKey: getModulePagePakKey,
            },
          },
          {
            path: 'notebook',
            component: FeedbackNotebook,
            name: 'pak_ov_notebook',
            meta: {
              moduleKey: getModulePagePakKey,
            },
          },
          {
            path: 'notebook_solution',
            component: FeedbackNotebook,
            name: 'pak_ov_notebook_solution',
            meta: {
              moduleKey: getModulePagePakKey,
            },
          },
          {
            path: 'leaderboard',
            component: FeedbackLeaderboard,
            name: 'pak_ov_leaderboard',
            meta: {
              moduleKey: getModulePagePakKey,
            },
          },
        ],
      },
      {
        path: 'quiz/:moduleQuizId',
        component: QuizPage,
        name: 'module_quiz',
      },
      {
        path: 'calendar_event/:moduleCalendarEventId',
        component: CalendarEventPage,
        name: 'module_calendar_event',
      },
      {
        path: 'meeting/:moduleMeetingId',
        component: MeetingPage,
        name: 'module_meeting',
      },
      {
        path: 'markdown_page/:moduleMarkdownPageId',
        component: ModuleMarkdownPage,
        name: 'module_markdown_page',
      },
      {
        path: 'questionnaire/:moduleQuestionnaireId',
        component: ModuleQuestionnaire,
        name: 'module_questionnaire',
      },
      {
        path: 'knowledge',
        component: ModuleKnowledgeBase,
        name: 'module_knowledge_base',
      },
      {
        path: 'knowledge/thread/:threadId',
        component: ThreadPage,
        name: 'knowledge_thread',
      },
    ],
  },
  {
    path: '/learning_environments/kods/:assetType/:assetId',
    component: SpinKodPage,
    name: 'spin_kod_page',
    kate_sidebar_disabled: true,
  },
  {
    path: '/maintenance',
    component: MaintenancePage,
    name: 'maintenance',
    kate_sidebar_disabled: true,
    kate_is_public: true,
  },
  {
    path: '/profile',
    component: ProfileOverview,
    children: [
      {
        path: '',
        component: ProfileLanding,
        name: 'profile_landing',
      },
      {
        path: 'information',
        component: ProfileInformation,
        name: 'profile_information',
      },
      {
        path: 'email',
        component: ProfileEmailAddress,
        name: 'profile_email_address',
      },
      {
        path: 'password',
        component: ProfilePassword,
        name: 'profile_password',
      },
      {
        path: 'knowledge',
        component: ProfileQuestions,
        name: 'profile_questions',
      },
      {
        path: 'knowledge/thread/:threadId',
        component: ThreadPage,
        name: 'profile_knowledge_thread',
      },
      {
        path: 'achievements',
        component: ProfileAchievements,
        name: 'profile_achievements',
      },
      // Redirect for the achievements view on the old badge route
      {
        path: 'badges',
        name: 'legacy_profile_badges',
        redirect: { name: 'profile_achievements' },
      },
      {
        path: 'git_settings',
        component: ProfileGitSettings,
        name: 'profile_git_settings',
      },
      {
        path: 'kods',
        component: ManageKodsPage,
        name: 'profile_manage_kods_page',
      },
    ],
  },
  {
    path: '/change_password',
    component: ChangePasswordPage,
    name: 'change_password_page',
    kate_sidebar_disabled: true,
    kate_is_public: true,
  },
  {
    path: '/help',
    component: MainHelpPage,
    name: 'help',
    kate_sidebar_disabled: true,
  },

  /* ==== HTML Help Pages ==== */

  {
    path: '/help/advanced_setup',
    component: AdvancedSetup,
    name: 'help_advanced_setup',
    kate_sidebar_disabled: true,
  },
  {
    path: '/help/fingerprints',
    component: FingerprintPage,
    name: 'help_kate_fingerprints',
    kate_sidebar_disabled: true,
  },
  {
    path: '/policy',
    component: PolicyAcceptPage,
    name: 'policy_accept_page',
    kate_sidebar_disabled: true,
  },
  {
    path: '/policy/:policyType',
    component: PolicyPage,
    name: 'policy_page',
    props: true,
    kate_sidebar_disabled: true,
  },
  {
    path: '/user_not_in_programme',
    component: UserNotInProgramme,
    name: 'user_not_in_programme',
    kate_sidebar_disabled: true,
  },
  /* ==== 404 ==== */
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: '404',
    kate_sidebar_disabled: true,
  },
];

export default routes;

export { getModulePagePakKey };
